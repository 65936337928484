import React, {Component} from 'react'
import Proptypes from 'prop-types'
import { connect } from 'react-redux'

import {
	Flex,
	Heading,
	Block,
	Paragraph,
} from 'reakit'

import { Container, Form, TopBar, HomeFooter } from '../../../components/layouts'


import {
	Card,
	Image,
	Collapsible,
	Loader
} from '../../../components/ui'

import {
	register, clearAuth, getOrganization
} from '../../../factory'

import theme from '../../../theme/content'
import Layout from '../../../components/layout'

class OrgRegistration extends Component {
	constructor(props) {
		super(props)
		this.state = {
			org_id: null
		}


		this.content = theme.pagesContent.register
		this.faqs = theme.pagesContent.faqs
		this.setRegisterValues = this.setRegisterValues.bind(this)
		this.submitRegisterForm = this.submitRegisterForm.bind(this)
		this.submitRegisterValidate = this.submitRegisterValidate.bind(this)
	}

	componentDidMount() {
		this.props.clearAuth()
		this.setOrg(typeof(window) !== 'undefined' && parseInt(window.location.pathname.split('/')[3], 10))
	}

	setOrg(org_id) {
		this.setState({org_id})
		this.props.getOrganization(org_id)
	}

	setRegisterValues(e) {
		if (e.target) {
			this.setState({
				[e.target.name]: e.target.value,
			})
		}
	}

	submitRegisterValidate() {
		if (!this.state.f_name) {
			this.setState({error: 'First name must be populated.'})
			return false
		} else if (!this.state.l_name) {
			this.setState({error: 'Last name must be populated.'})
			return false
		} else if (!this.state.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
			this.setState({error: 'Email must be populated with the correct format.'})
			return false
		} else if (!this.state.phone) {
			this.setState({error: 'Phone name must be populated.'})
			return false
		} else if (!this.state.password) {
			this.setState({error: 'Password must be populated.'})
			return false
		} else if (!this.state.password_confirm) {
			this.setState({error: 'Re-enter password must be populated.'})
			return false
		} else if (this.state.password !== this.state.password_confirm) {
			this.setState({error: 'Passwords must match.'})
			return false
		}

		return true
	}

	submitRegisterForm(e) {
		e.preventDefault()


		if (this.submitRegisterValidate()) {
			const { pageContext: { org = {} }, organization } = this.props
			const finalOrg = Object.assign(org, organization)
			const data = {
				email: this.state.email,
				password: this.state.password,
				first_name: this.state.f_name,
				last_name: this.state.l_name,
				phone: this.state.phone,
				organization_id: finalOrg.organization_id,
				project_ids: [finalOrg.default_project_id]
			}
			this.props.registerAccount(data)
		}
	}

	addDefaultSrc(ev) {
		ev.target.src = 'https://flourishfiles.blob.core.windows.net/files/Marketing/Patterns/LogoPatternWebSmall.jpg'
	}

	render() {
		const { pageContext: { org = {} }, discoverPending, organization } = this.props
		const finalOrg = Object.assign(org, organization)
		return(
			<Layout>
				<TopBar />
				<Container style={{justifyContent: 'top'}}>
					<Flex
						width={'100%'}
						alignItems={'center'}
						justifyContent={'start'}
						flexDirection={'column'}
						paddingTop={100}
						background={theme.colors.sunglow}
					>
						<Card
							width="80%"
							border={`1px solid ${theme.colors.lightGray}`}
							minWidth="350px"
							display={'flex'}
							flexDirection={"column"}
							height={'auto'}
						>
							<Heading as="h1" margin={'0px'} marginBottom={10} lineHeight={'1em'} textAlign={'center'}>
								Round up for {finalOrg.title}
							</Heading>
							<Paragraph style={{marginTop: 0, textAlign: 'center'}}>
                                Sign up today to round up for {finalOrg.title}!
							</Paragraph>
							<Flex row flexWrap={'wrap'} >
								<Flex column flex={"1 1 300px"} alignItems={'center'} marginBottom={"25px"}>
									<Image
										onError={this.addDefaultSrc}
										src={finalOrg.image_url}
										height={'auto'}
										width={'80%'}
										maxWidth={'250px'}
									/>
									<Form
										style={{marginBottom: 0}}
										fields={this.content.registerForm.steps.first}
										setValues={this.setRegisterValues}
										submitForm={this.submitRegisterForm}
										submitBtnText={this.content.registerForm.submitField.text}
										error={this.props.error || this.state.error}
										logging={this.props.pending}
									/>
									<Paragraph style={{fontSize: 10, margin: 0, lineHeight: '1em', marginTop: 10, textAlign: 'left'}}>By submitting this form, you agree to our <a href="https://www.flourishchange.com/terms-and-conditions#top">terms of service</a> and <a href="https://www.flourishchange.com/privacy#top">privacy policy.</a></Paragraph>
								</Flex>
								<Flex column flex={"1 1 300px"}>
									<Heading width={'100%'} textAlign={'center'} as="h1" lineHeight={'1em'} marginBottom={'5px'}>How Does it Work?</Heading>
									<Paragraph width={'80%'} textAlign={'center'} margin={'auto'} marginTop={'0'}>	By linking your credit and debit card(s), we’ll roundup your everyday transactions and donate them to {finalOrg.title}!</Paragraph>
									<Flex row flexWrap={'wrap'} marginBottom={15}>
										<Block style={{flex: "1 1 100px"}} paddingLeft={'15px'} marginRight={"10px"}>
											<Image
												src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding1.png'}
												height={'150px'}
												width={'auto'}
											/>
										</Block>
										<Flex style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: "3 1 300px"}}>
											<Heading as={'h3'} fontWeight={900}>Step 1:</Heading>
											<Paragraph fontSize={24} lineHeight={'32px'}><Paragraph fontWeight={900} display={'inline'}>Sign Up For Flourish</Paragraph> with some basic information.</Paragraph>
										</Flex>

									</Flex>
									<Flex row flexWrap={'wrap'} marginBottom={15}>
										<Block style={{flex: "1 1 100px"}} paddingLeft={'15px'} marginRight={"10px"}>
											<Image
												src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2.png'}
												height={'150px'}
												width={'auto'}
											/>
										</Block>
										<Flex style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: "3 1 300px"}}>
											<Heading as={'h3'} fontWeight={900}>Step 2:</Heading>
											<Paragraph fontSize={24} lineHeight={'32px'}><Paragraph fontWeight={900} display={'inline'}>Link Your Bank Accounts</Paragraph> using the same system as Venmo and Robinhood.</Paragraph>
										</Flex>

									</Flex>
									<Flex row flexWrap={'wrap'} marginBottom={15}>
										<Block style={{flex: "1 1 100px"}} paddingLeft={'15px'} marginRight={"10px"}>
											<Image
												src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding4.png'}
												height={'150px'}
												width={'auto'}
											/>
										</Block>
										<Flex style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: "3 1 300px"}}>
											<Heading as={'h3'} fontWeight={900}>Step 3:</Heading>
											<Paragraph fontSize={24} lineHeight={'32px'}><Paragraph fontWeight={900} display={'inline'}>Watch Your Impact</Paragraph> with automatic roundups and donations. </Paragraph>
										</Flex>
									</Flex>
								</Flex>
							</Flex>
							<Heading width={'100%'} textAlign={'center'} as="h1" lineHeight={"1em"}>Have More Questions?</Heading>
							<Flex column width={"80%"} margin={'auto'} marginBottom={25}>
								<Collapsible list={this.faqs.questions}/>
							</Flex>
						</Card>
					</Flex>
					{discoverPending && Object.keys(org).length === 0 && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
						<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
							<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
								<Loader/>
								<Heading as="h1" fontSize={24}>Loading...</Heading>
							</div>
						</div>
					</div>
					}
				</Container>
				<HomeFooter />
			</Layout>
		)
	}
}

OrgRegistration.propTypes = {
	pageContext: Proptypes.object,
	org: Proptypes.object,
	registerAccount: Proptypes.func,
	pending: Proptypes.bool,
	error: Proptypes.string,
	clearAuth: Proptypes.func,
	organization: Proptypes.object,
	discoverPending: Proptypes.bool,
	getOrganization: Proptypes.func,
	getOrgError: Proptypes.string
}

const mapStateToProps = (state) => {
	return {
		pending: state.authState.pending,
		error: state.authState.error,
		organization: state.discoverState.activeOrg,
		discoverPending: state.discoverState.pending,
		getOrgError: state.discoverState.categoryError
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		registerAccount: (data, callback) => dispatch(register(data, callback)),
		clearAuth: ()=> dispatch(clearAuth()),
		getOrganization: (oid) => dispatch(getOrganization(oid))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(OrgRegistration)